import { useContext, useState } from "react";
import { Button, Divider } from "antd";
import { updateConsents } from "../requests/requests";
import { Context } from "../context/context";
import { Spin } from "antd";

export default function UpdateConsents() {
  const { keycloak, config } = useContext(Context);
  const [updateResult, setUpdateResult] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClickUpdate = async () => {
    setLoading(true);
    setUpdateResult("");
    setUpdateMessage("");
    const response = await updateConsents(keycloak, config);
    if (response) {
      setUpdateResult(response.result);
      setUpdateMessage(response.message);
    }
    setLoading(false);
  };

  const displayUpdateResult = () => {
    if (updateResult === "success") {
      return <p className="success">The consents have been updated.</p>;
    } else if (updateResult === "not-modified") {
      return <p>No update has been necessary.</p>;
    } else if (updateResult === "not-found") {
      return (
        <p className="error-message">
          No consents have been found - update failed.
        </p>
      );
    } else if (updateResult === "error") {
      return <p className="error-message">An unknown error has occurred.</p>;
    }
  };

  return (
    <Divider>
      <h4>Update Consents</h4>
      <p>Update the database of stored consents:</p>
      <Button type="primary" htmlType="button" onClick={handleClickUpdate}>
        Update
      </Button>
      {updateResult && displayUpdateResult()}
      <p className="output-value">{updateMessage}</p>
      {loading && <Spin></Spin>}
    </Divider>
  );
}
