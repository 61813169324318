import React, { useEffect, useState } from 'react'
import { User } from '../requests/requests'
import { Context } from '../context/context';
import { getIdsResultType } from '../context/api';
import { Table } from 'antd';

function UserIds({showUser}:{showUser?: User}) {
  const { config, api } = React.useContext(Context);
  const [data, setData] = useState<getIdsResultType[]>();
  function sentryLink(id: string) {
    return `https://sentry.comjoo.com/organizations/comjoo/issues/?project=3&query=user%3A%22id%3A${id}%22&statsPeriod=14d`;
  }

  function keycloakLink(id: string) {
    return `${config.keycloak.url}/admin/master/console/#/realms/${config.keycloak.realm}/users/${id}`;
  }

  const renderResultsInTable = () => {
    if (!data) return;

    const columns = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "PM Id",
        dataIndex: "pmId",
        key: "pmId",
      },
      {
        title: "Fhir Id",
        dataIndex: "fhirId",
        key: "fhirId",
      },
      {
        title: "Links",
        key: "Links",
        render: (e: any) => (
          <>
            <a href={sentryLink(e.pmId)}>
              <img
                src="/logo_sentry.png"
                className="logo_img"
                alt="sentry link"
              />
            </a>
            {e.kcId && (
              <a style={{ marginLeft: 10 }} href={keycloakLink(e.kcId)}>
                <img
                  src="/logo_keycloak.png"
                  className="logo_img"
                  alt="keycloak link"
                />
              </a>
            )}
          </>
        ),
      },
    ];

    const paginationConfig = data.length > 10 ? { pageSize: 10 } : false;
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={paginationConfig}
      />
    );
  };

  useEffect(()=>{
    if (!showUser) return;
    api?.getIds(showUser).then(setData);
  }, [showUser])
  
  return (
    <>{data ? <div>{renderResultsInTable()}</div> : <div>...</div>}</>
  )
}

export default UserIds