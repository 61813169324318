import { useContext, useState } from "react";
import { Button, Form, Input, Radio } from "antd";
import FormItemLabel from "antd/es/form/FormItemLabel";
import { createOrg } from "../requests/requests";
import { capitalize } from "../util/text";
import { Context } from "../context/context";

const defaultGroupType: string = "organization";

type FormInputType = {
  type: string;
  [key: string]: string;
};

type ResultType =
  | { password: any; fhirId: any; hasError?: undefined }
  | { hasError: boolean; password?: undefined; fhirId?: undefined };

export default function RegisterGroup() {
  const { keycloak, config } = useContext(Context);
  const [formInput, setFormInput] = useState<FormInputType>({
    type: defaultGroupType,
  });
  const [createGroupResponse, setCreateGroupResponse] =
    useState<ResultType | null>(null);
  const [responseGroupType, setResponseGroupType] = useState(defaultGroupType);

  const handleGroupTypeChange = (e: any) => {
    setFormInput((prev) => ({ ...prev, type: e.target.value }));
  };

  const handleInputChange = (e: any) => {
    setFormInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    const result = await createOrg(keycloak, config, formInput);
    setCreateGroupResponse(result);
    setResponseGroupType(formInput.type);
  };

  return (
    <>
      <h2>Register Group</h2>
      <Form onFinish={handleSubmit} onChange={handleInputChange}>
        <FormItemLabel
          htmlFor="select-group-type"
          label="Which type of group do you want to create?"
          prefixCls="ant-formItemLabel"
        />
        <Radio.Group
          id="select-group-type"
          onChange={handleGroupTypeChange}
          defaultValue={defaultGroupType}
          style={{ marginTop: 8, marginBottom: 20 }}
        >
          <Radio value="organization">Organization</Radio>
          <Radio value="clinic">Clinic</Radio>
        </Radio.Group>
        <Form.Item label="Name" className="form-item" required colon>
          <Input
            name="name"
            value={formInput.name}
            required
            className="input"
          />
        </Form.Item>
        <Form.Item label="Street" className="form-item" required colon>
          <Input
            name="street"
            value={formInput.street}
            required
            className="input"
          />
        </Form.Item>
        <Form.Item label="City" className="form-item" required colon>
          <Input
            name="city"
            value={formInput.city}
            required
            className="input"
          />
        </Form.Item>
        <Form.Item label="Postal Code" className="form-item" required colon>
          <Input
            name="postalCode"
            value={formInput.postalCode}
            required
            className="input"
          />
        </Form.Item>
        <Form.Item label="Email" className="form-item" required colon>
          <Input
            name="email"
            value={formInput.email}
            required
            className="input"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">{`Create ${capitalize(
            formInput.type
          )}!`}</Button>
        </Form.Item>
      </Form>
      {createGroupResponse?.hasError && (
        <p className="error-message">Could not create group.</p>
      )}
      {createGroupResponse?.password && (
        <div className="success">
          <p>
            You have successfully created the {responseGroupType}{" "}
            {formInput.name}:
          </p>
          {createGroupResponse?.fhirId && (
            <p className="output-entry">
              Fhir id: {createGroupResponse?.fhirId}
            </p>
          )}
          {createGroupResponse?.password && (
            <p className="output-entry">
              Password: {createGroupResponse?.password}
            </p>
          )}
        </div>
      )}
    </>
  );
}
