import { Form, Input } from "antd";
import { getDataTypeDisplay } from "../util/getDataTypeDisplay";

interface EmailOrIdProps {
  searchType?: any;
  handleChange: (...args: any[]) => any;
  customInputName?: string;
  required?: boolean;
}

export default function EmailOrId({
  searchType,
  handleChange,
  customInputName,
  required = true,
}: EmailOrIdProps) {
  const getLabel = () => {
    if (customInputName === "new-email") {
      return "New Email";
    } else if (customInputName === "fhir-org-id") {
      return "Fhir Organization Id";
    } else {
      return getDataTypeDisplay(searchType);
    }
  };

  return (
    <Form.Item
      label={getLabel()}
      className="user-input"
      required={required}
      colon
    >
      <Input
        name={customInputName ? customInputName : searchType}
        required={required}
        style={{ width: 300 }}
        onChange={handleChange}
      />
    </Form.Item>
  );
}
