import { getEmailsAndIds } from '../requests/requests';
import Request from '../components/Request';
import UserInfo from '../components/UserInfo';


export default function UserData() {

    return (<>
        <UserInfo></UserInfo>
        <Request
            type='get-id'
            title='Search Ids'
            displayShowDataCheckbox={false}
            requestFunction={getEmailsAndIds}
        />
    </>)
}