import { useContext, useState } from "react";
import { Button, Divider, Form, InputNumber, Table } from "antd";
import { getSyncedStatus } from "../requests/requests";
import { Context } from "../context/context";

const defaultCount: number = 25;

export default function MessageOverview() {
  const { config, keycloak } = useContext(Context);
  const [count, setCount] = useState<number | null>(defaultCount);
  const [info, setInfo] = useState<any | null>(null);

  const handleCountChange = (value: number | null) => {
    setCount(value);
  };

  const handleSubmit = async () => {
    const result = await getSyncedStatus(keycloak, config, count);
    setInfo(result);
  };

  const getErrorTable = () => {
    const columns = [
      {
        title: "Total number of errors",
        dataIndex: "count",
        key: "0",
        render: (count: number) => {
          return (
            <span
              className={count > 0 ? "message-error" : "no-message-error"}
              style={{ marginLeft: 10 }}
            >
              {count}
            </span>
          );
        },
      },
      {
        title: "Time of first error",
        dataIndex: "firstError",
        key: "1",
        render: (text: string) => {
          return (
            <span style={{ marginLeft: text === "-" ? 50 : 0 }}>{text}</span>
          );
        },
      },
      {
        title: "Time of last error",
        dataIndex: "lastError",
        key: "2",
        render: (text: string) => {
          return (
            <span style={{ marginLeft: text === "-" ? 50 : 0 }}>{text}</span>
          );
        },
      },
    ];
    const values = [
      {
        count: info?.errors?.count,
        firstError: info?.errors?.firstError ? info.errors.firstError : "-",
        lastError: info?.errors?.lastError ? info.errors.lastError : "-",
        key: "0",
      },
    ];
    if (!info?.errors) return;
    return (
      <Table
        columns={columns}
        dataSource={values}
        style={{ maxWidth: 600 }}
        pagination={false}
      />
    );
  };

  const getInfoTable = () => {
    const columns = [
      {
        title: "Call Nr",
        dataIndex: "callNr",
        key: "0",
      },
      {
        title: "Errored",
        dataIndex: "errored",
        key: "1",
        render: (errorNum: any) => (
          <span
            className={errorNum === 0 ? "no-message-error" : "message-error"}
          >
            {errorNum}
          </span>
        ),
      },
      {
        title: "Synced",
        dataIndex: "synced",
        key: "2",
      },
      {
        title: "Start Time",
        dataIndex: "startTime",
        key: "3",
      },
      {
        title: "End Time",
        dataIndex: "endTime",
        key: "4",
      },
    ];
    const values = info?.runs?.map((run: any) => {
      return { ...run, key: run.callNr };
    });
    if (!info?.runs || info?.runs.length === 0 || info?.hasError) {
      return (
        <p className="error-message">
          Could not get information about messages.
        </p>
      );
    }
    return (
      <Table columns={columns} dataSource={values} style={{ maxWidth: 800 }} />
    );
  };

  return (
    <Divider>
      <h4>Message Overview</h4>
      <Form.Item label="Number of Counts">
        <InputNumber
          defaultValue={defaultCount}
          min={1}
          max={25}
          onChange={handleCountChange}
        />
      </Form.Item>
      <Button
        type="primary"
        htmlType="button"
        onClick={handleSubmit}
        style={{ marginBottom: 20 }}
      >
        Get Message Overview
      </Button>
      <Divider>
        {info && getErrorTable()}
        {info && getInfoTable()}
      </Divider>
    </Divider>
  );
}
