import React, { useState } from 'react'
import UserFinder from './UserFinder';
import { User, deleteUserData } from '../requests/requests';
import { Button, Checkbox, Divider, Form, Modal } from 'antd';
import { Context } from '../context/context';

function UserDelete({showUser, onUserDeleted}:{showUser?: User, onUserDeleted?: (a:any)=>void}) {
  const { api } = React.useContext(Context);
  const [user, setUser] = useState<User|undefined>(showUser);
  const [partialDelete, setPartialDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function display(user: User) {
    const problems = user.problems();

    return (<>
      <div>User: <b>{user.descriptor()}</b></div>
      {problems ? <div>
        {problems.im?.missing ? <div>Missing im information</div> : ''}
        {problems.pm?.missing ? <div>Missing pm information</div> : ''}
      </div> : 'No issues'}
    </>)
  }

  async function deleteUser(user: User) {
    const result = await api?.deleteUser(user, { partialDelete })
    setShowModal(false);
    setUser(undefined);
    if (onUserDeleted) onUserDeleted(result);
  }

  return (
    <div>

      {user ?

        <>
          <Modal open={showModal}
            title="Are you sure?"
            onCancel={() => { setShowModal(false) }}
            onOk={() => deleteUser(user)}
          >
            Deleting{partialDelete ? ' (partially, best effort)' : ''}:
            <div>{display(user)}</div>
            <br />
            This action cannot be undone!

          </Modal>
          {display(user)}
          <div>
            <Button danger type="primary" onClick={() => setShowModal(true)}>Delete</Button>
            <Button danger type="text" onClick={() => setUser(undefined)}>Clear</Button>

          </div>
          <Form>
            <Form.Item label="also partially delete?" help="In case there are missing parts, best effort deletion.">
              <Checkbox checked={partialDelete}
                onChange={() => setPartialDelete(p => !p)}></Checkbox>
            </Form.Item>
          </Form>

        </>
        :
        <UserFinder onUserFound={(foundUser: any) => setUser(foundUser)} />
      }
    </div>
  )
}

export default UserDelete;