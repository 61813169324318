import React, { useState } from "react";
import { Divider, Button, Form, Checkbox, Flex } from "antd";
import ResponseData from "./ResponseData";
import CustomModal from "./CustomModal";
import SearchType from "./SearchType";
import { downloadTextFile } from "../util/downloadTextFile";
import EmailOrId from "./EmailOrId";
import CustomDatePicker from "./CustomDatePicker";
import { Context } from "../context/context";

const defaultSearchType: string = "email";

interface RequestProps {
  type: string;
  title: string;
  displayNewEmailInput?: boolean;
  displayShowDataCheckbox?: boolean;
  displayPicker?: boolean;
  showModal?: boolean;
  requestFunction?: (
    keycloak: any,
    config: any,
    requestData: any,
    searchType: any
  ) => Promise<any>;
}

type DateType = {
  year: any;
  month: any;
};

type UserData = {
  data: any;
  [key: string]: any;
};

// TODO: clena this by having multiple widgets and if anything common services or sub-widgets
export default function Request({
  type,
  title,
  displayNewEmailInput,
  displayShowDataCheckbox,
  displayPicker,
  showModal,
  requestFunction,
}: RequestProps) {
  const { keycloak, config } = React.useContext(Context);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [searchType, setSearchType] = useState(defaultSearchType);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [newEmail, setNewEmail] = useState("");
  const [date, setDate] = useState<DateType | null>(null);
  const [showUserData, setShowUserData] = useState(true);
  const [requestSent, setRequestSent] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [responseData, setResponseData] = useState<any | null>(null);
  const [partialDeletion, setPartialDeletion] = useState(false);

  const handleSearchTypeChange = (value: string) => {
    setSearchType(value);
  };

  const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleNewEmailInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewEmail(e.target.value);
  };

  const handleShowUserDataChange = () => {
    setShowUserData((prev) => !prev);
  };

  const handleSubmit = async () => {
    if (showModal) {
      setDisplayModal(true);
      return;
    }
    await sendRequest();
  };

  const sendRequest = async () => {
    let userData: any;
    try{
      if (type === "change-email") {
        const changeEmailData = {
          searchTerm,
          newEmail,
        };
        userData = await requestFunction?.(
          keycloak,
          config,
          changeEmailData,
          searchType
        );
      } else if (type === "consents-patient" || type === "consents-org") {
        const getConsentData = {
          searchTerm,
          year: date?.year,
          month: date?.month,
        };
        userData = await requestFunction?.(
          keycloak,
          config,
          getConsentData,
          searchType
        );
      } else {
        userData = await requestFunction?.(
          keycloak,
          config,
          searchTerm,
          searchType
        );
      }
      if (userData && type === "get-user-data") {
        downloadTextFile(userData);
      }
    } catch (err){
      console.log("Error: ", err);
      userData = { hasError: true }
    }
    setUserData(userData);
    setResponseData({
      data: userData,
      searchType,
      searchTerm,
    });
    setRequestSent(true);
  };

  const handleCancelModal = () => {
    setDisplayModal(false);
  };

  const handleOkModal = async () => {
    setDisplayModal(false);
    await sendRequest();
  };

  return (
    <>
      <Divider><h4>{title}</h4></Divider>
      <Flex  justify="center">
        <Form onFinish={handleSubmit} style={{ maxWidth: 300 }}>
          {type !== "consents-org" && (
            <SearchType
              defaultSearchType={defaultSearchType}
              handleSearchTypeChange={handleSearchTypeChange}
            />
          )}
          <EmailOrId
            searchType={type === "consents-org" ? "fhirId" : searchType}
            handleChange={handleUserInputChange}
          />
          {displayNewEmailInput && (
            <EmailOrId
              handleChange={handleNewEmailInputChange}
              customInputName="new-email"
            />
          )}
          {displayPicker && <CustomDatePicker setDate={setDate} />}
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
          {displayShowDataCheckbox && (
            <><Form.Item label="Show data">
              <Checkbox
                checked={showUserData}
                onChange={handleShowUserDataChange}
              />
            </Form.Item>
          </>
          )}
        </Form>
        </Flex>
        {requestSent && (
          <ResponseData
            type={type}
            showUserData={showUserData}
            responseData={responseData}
          />
        )}
        {requestSent && userData?.data?.pm && showUserData && (
          <Form.Item
            label="Show data"
            style={{ width: 100, marginTop: 20 }}
            colon
          >
            <Checkbox
              checked={showUserData}
              onChange={handleShowUserDataChange}
            />
          </Form.Item>
        )}
        <CustomModal
          type={type}
          searchType={searchType}
          searchTerm={searchTerm}
          newEmail={newEmail}
          displayModal={displayModal}
          handleOkModal={handleOkModal}
          handleCancelModal={handleCancelModal}
        />
        </>
  );
}
