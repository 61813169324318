import { useContext, useState } from "react";
import { Button, Divider, Table } from "antd";
import { countConsents } from "../requests/requests";
import { Context } from "../context/context";

export default function TotalConsents() {
  const { keycloak, config } = useContext(Context);
  const [data, setData] = useState<any | null>(null);

  const handleClick = async () => {
    const result = await countConsents(keycloak, config);
    setData(result);
  };

  const getTable = () => {
    const columns = [
      {
        title: "Organization",
        dataIndex: "orgName",
        key: "0",
      },
      {
        title: "PM Org Id",
        dataIndex: "pmOrgId",
        key: "1",
      },
      {
        title: "Fhir Org Id",
        dataIndex: "fhirOrgId",
        key: "2",
      },
      {
        title: "Active Consents",
        dataIndex: "numConsents",
        key: "3",
      },
    ];
    const values = data?.orgCounts.map((countData: any) => {
      return {
        orgName: countData.name,
        pmOrgId: countData.pmOrgId,
        fhirOrgId: countData.fhirOrgId,
        numConsents: countData.count,
        key: countData.fhirOrgId,
      };
    });
    return <Table columns={columns} dataSource={values} pagination={false} />;
  };

  const getOutput = () => {
    if (!data || data.hasError) {
      return (
        <p className="error-message">Could not load counts of consents.</p>
      );
    } else {
      return (
        <div style={{ whiteSpace: "normal", maxWidth: "600px" }}>
          <p>
            Total number of consents:{" "}
            <span id="total-count">{data.totalCount}</span>
          </p>
          <p>Counts per organization:</p>
          {getTable()}
        </div>
      );
    }
  };

  return (
    <Divider>
      <h4>Count Consents</h4>
      <p>Get number of consents per organization:</p>
      <Button type="primary" onClick={handleClick}>
        Get counts
      </Button>
      {data && getOutput()}
    </Divider>
  );
}
