import { getUniqueKey } from "./getUniqueKey";
import { convertBooleanToString } from "./text";

interface LineProps {
  children: any;
  level: number;
}

function Line({ children, level }: LineProps) {
  return (
    <p className="output-value" style={{ marginLeft: level * 25 }}>
      {children}
    </p>
  );
}

export function printUserData(obj: any, arr: any[] = [], level: number = 0) {
  const keys = Object.keys(obj);
  keys.forEach((k) => {
    if (typeof obj[k] === "object" && obj[k] !== null && obj[k].length >= 0) {
      if (obj[k].length === 0) {
        arr.push(
          <Line key={getUniqueKey()} level={level}>
            {k}: {"<no value>"}
          </Line>
        );
      } else {
        arr.push(
          <Line key={getUniqueKey()} level={level}>
            {k}:
          </Line>
        );
        obj[k].forEach((el: any, index: number) => {
          arr.push(
            <Line key={getUniqueKey()} level={level + 1}>
              {k} no. {index + 1}:{" "}
            </Line>
          );
          return printUserData(el, arr, level + 2);
        });
      }
    } else if (typeof obj[k] === "object" && obj[k] !== null) {
      if (Object.keys(obj[k]).length === 0) {
        arr.push(
          <Line key={getUniqueKey()} level={level}>
            {k}: {"<no value>"}
          </Line>
        );
      } else {
        arr.push(
          <Line key={getUniqueKey()} level={level}>
            {k}:
          </Line>
        );
        return printUserData(obj[k], arr, level + 1);
      }
    } else {
      arr.push(
        <Line key={getUniqueKey()} level={level}>
          {k}: {obj[k] ? convertBooleanToString(obj[k]) : "<no value>"}
        </Line>
      );
    }
  });
  return arr;
}
