import { useState } from "react";
import { Form, Radio, DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";

interface CustomDatePickerProps {
  setDate: any;
}

export default function CustomDatePicker({ setDate }: CustomDatePickerProps) {
  const [dateMode, setDateMode] = useState("none");

  const handleDateChange = (value: any) => {
    if (!value) return;
    setDate({
      year: value.$y,
      month: dateMode === "month" ? value.$M + 1 : undefined,
    });
  };

  const handleDateModeChange = (e: any) => {
    const newMode = e.target.value;
    const year = dayjs().year();
    const month = dayjs().month() + 1;
    setDateMode(newMode);
    if (newMode === "none") {
      setDate(null);
    } else if (newMode === "year") {
      setDate({ year });
    } else if (newMode === "month") {
      setDate({ year, month });
    }
  };

  const getPicker = () => {
    if (dateMode === "none") return;
    else if (dateMode === "year") {
      return (
        <Form.Item label="Year" colon>
          <DatePicker
            picker="year"
            defaultValue={dayjs()} // dayjs() returns the current date
            onChange={handleDateChange}
          />
        </Form.Item>
      );
    } else if (dateMode === "month") {
      return (
        <Form.Item label="Year - Month" colon>
          <DatePicker
            picker="month"
            defaultValue={dayjs()}
            onChange={handleDateChange}
          />
        </Form.Item>
      );
    }
  };

  return (
    <>
      <Form.Item label="Time Span" colon>
        <Radio.Group
          value={dateMode}
          onChange={handleDateModeChange}
          style={{ marginLeft: 30 }}
        >
          <Radio value="none">None</Radio>
          <Radio value="year">Year</Radio>
          <Radio value="month">Month</Radio>
        </Radio.Group>
      </Form.Item>
      {getPicker()}
    </>
  );
}
