import { useState, useContext } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Radio,
  Table,
  RadioChangeEvent,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { capitalize } from "../util/text";
import SearchType from "./SearchType";
import EmailOrId from "./EmailOrId";
import { checkMessages } from "../requests/requests";
import { Context } from "../context/context";

const defaultDateMode: string = "from";
const defaultSenderType: string = "organization";
const defaultRecipientType: string = "patient";
const defaultSync: boolean = false;
const defaultCount: number = 20;

interface MessageResponse {
  // Define the structure of message response or use any if not sure
  // Example: id: string; sender: string; recipient: string; ...
  [key: string]: any;
}

interface QueryType {
  sender: string | null; // Adjust the types based on what these values can be
  senderType: string;
  recipient: string | null;
  recipientType: string;
  searchType: string;
  sync: boolean;
  count: number | null;
  fromDate?: string; // Optional properties
  toDate?: string;
}

export default function CheckMessages() {
  const { keycloak, config } = useContext(Context);
  const [dateMode, setDateMode] = useState<string>(defaultDateMode);
  const [fromDate, setFromDate] = useState<Dayjs>(dayjs());
  const [toDate, setToDate] = useState<Dayjs>(dayjs());
  const [sender, setSender] = useState<string | null>(null);
  const [recipient, setRecipient] = useState<string | null>(null);
  const [senderType, setSenderType] = useState<string>(defaultSenderType);
  const [recipientType, setRecipientType] =
    useState<string>(defaultRecipientType);
  const [searchType, setSearchType] = useState<string>("fhirId");
  const [sync, setSync] = useState<boolean>(defaultSync);
  const [count, setCount] = useState<number | null>(defaultCount);
  const [messagesResponse, setMessagesResponse] = useState<
    MessageResponse[] | null
  >(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleFromDateChange = (value: any) => {
    setFromDate(value);
  };

  const handleToDateChange = (value: any) => {
    setToDate(value);
  };

  const getPicker = (dateType: string) => {
    return (
      <Form.Item label={capitalize(dateType)} colon>
        <DatePicker
          picker="date"
          defaultValue={dayjs()} // dayjs() returns the current date
          onChange={
            dateType === "from" ? handleFromDateChange : handleToDateChange
          }
        />
      </Form.Item>
    );
  };

  const handleSenderTypeChange = (e: RadioChangeEvent) => {
    setSenderType(e.target.value);
  };

  const handleRecipientTypeChange = (e: RadioChangeEvent) => {
    setRecipientType(e.target.value);
  };

  const handleSearchTypeChange = (value: string) => {
    setSearchType(value);
  };

  const getParticipantInputField = (participantType: string) => {
    const handleInputChange = (e: { target: { value: string } }) => {
      if (participantType === "sender") {
        setSender(e.target.value);
      } else if (participantType === "recipient") {
        setRecipient(e.target.value);
      }
    };

    if (
      (participantType === "sender" && senderType === "patient") ||
      (participantType === "recipient" && recipientType === "patient")
    ) {
      return (
        <>
          <SearchType
            defaultSearchType="fhirId"
            handleSearchTypeChange={handleSearchTypeChange}
            required={false}
          />
          <EmailOrId
            searchType={searchType}
            handleChange={handleInputChange}
            required={false}
          />
        </>
      );
    } else if (
      (participantType === "sender" && senderType === "organization") ||
      (participantType === "recipient" && recipientType === "organization")
    ) {
      return (
        <>
          <EmailOrId
            customInputName="fhir-org-id"
            handleChange={handleInputChange}
            required={false}
          />
        </>
      );
    }
  };

  const getMessageParticipant = (participantType: string) => {
    return (
      <>
        <Form.Item label={capitalize(participantType)} colon>
          <Radio.Group
            onChange={
              participantType === "sender"
                ? handleSenderTypeChange
                : handleRecipientTypeChange
            }
            style={{ marginLeft: 30 }}
            defaultValue={
              participantType === "sender" ? senderType : recipientType
            }
          >
            <Radio value="patient">Patient</Radio>
            <Radio value="organization">Organization</Radio>
          </Radio.Group>
        </Form.Item>
        {getParticipantInputField(participantType)}
      </>
    );
  };

  const handleSyncChange = () => {
    setSync((prev) => !prev);
  };

  const handleCountChange = (value: number | null) => {
    setCount(value);
  };

  const handleSubmit = async () => {
    setMessagesResponse(null);
    setErrorMessage(null);

    let query: QueryType = {
      sender,
      senderType,
      recipient,
      recipientType,
      searchType,
      sync,
      count,
    };

    if (dateMode === "from-to") {
      if (fromDate && toDate) {
        const diff = toDate.diff(fromDate);
        if (diff < 0) {
          setErrorMessage(
            "The fromDate value cannot be later than the toDate value."
          );
          return;
        }
      }
      query.fromDate = fromDate.format("YYYY-MM-DD");
      query.toDate = toDate.format("YYYY-MM-DD");
    } else if (dateMode === "from") {
      query.fromDate = fromDate.format("YYYY-MM-DD");
    }

    const result = await checkMessages(keycloak, config, query);

    if (result?.length > 0) {
      setMessagesResponse(result);
    } else if (result?.length === 0) {
      setErrorMessage("No data have been found.");
    } else if (result?.error) {
      setErrorMessage(result?.error);
    } else {
      setErrorMessage("An unknown error has occurred.");
    }
  };

  const getTable = () => {
    if (!messagesResponse) return;

    const columns = [
      {
        title: "Message Id",
        dataIndex: "id",
        key: "0",
      },
      {
        title: "Sender (Fhir Id)",
        dataIndex: "sender",
        key: "1",
      },
      {
        title: "Recipient (Fhir Id)",
        dataIndex: "recipient",
        key: "2",
      },
      {
        title: "Date",
        dataIndex: "sentDate",
        key: "3",
      },
      {
        title: "Synced",
        dataIndex: "synced",
        key: "4",
      },
      {
        title: "Valid",
        dataIndex: "valid",
        key: "5",
      },
      {
        title: "Error while syncing?",
        dataIndex: "erroredWhileSyncing",
        key: "6",
      },
      {
        title: "Reason if not synced",
        dataIndex: "cause",
        key: "7",
      },
    ];

    const values = messagesResponse.map((entry) => {
      return {
        ...entry,
        synced: entry.synced ? "✅" : "❌",
        valid: entry.valid ? "✅" : "❌",
        erroredWhileSyncing: entry.erroredWhileSyncing ? "‼️" : "",
      };
    });

    return (
      <Table
        columns={columns}
        dataSource={values}
        expandable={{ fixed: "left" }}
        style={{ maxWidth: 750 }}
      />
    );
  };

  return (
    <Divider>
      <h4>Check Messages</h4>
      <Form onFinish={handleSubmit} style={{ maxWidth: 300 }}>
        <Form.Item label="Date Mode" colon>
          <Radio.Group
            onChange={(e) => setDateMode(e.target.value)}
            style={{ marginLeft: 30 }}
            value={dateMode}
          >
            <Radio value="none">None</Radio>
            <Radio value="from">From</Radio>
            <Radio value="from-to">From - To</Radio>
          </Radio.Group>
        </Form.Item>
        {dateMode !== "none" && getPicker("from")}
        {dateMode === "from-to" && getPicker("to")}
        {getMessageParticipant("sender")}
        {getMessageParticipant("recipient")}
        <Form.Item label="Sync" colon>
          <Checkbox checked={sync} onChange={handleSyncChange} />
        </Form.Item>
        <Form.Item label="Number of Counts">
          <InputNumber
            defaultValue={defaultCount}
            min={5}
            max={200}
            step={5}
            onChange={handleCountChange}
          />
        </Form.Item>
        <Button
          danger={sync}
          type="primary"
          htmlType="button"
          onClick={handleSubmit}
          style={{ marginBottom: 20 }}
        >
          {sync ? "Sync Messages" : "Get Messages"}
        </Button>
      </Form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {messagesResponse && getTable()}
    </Divider>
  );
}
