import local from "./local.json";
import dev from "./dev.json";
import stage from "./stage.json";
import prod from "./prod.json";
import teamedio from "./teamedio.json";
import * as buildInfoLocal from "../build.json";
import { log } from "../util/logger";

const allConfs: any = {
  local,
  dev,
  stage,
  prod,
  teamedio,
};

export interface ConfigType {
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
  };
  url: {
    admin: string;
    message: string;
  };
  env?: string;
  buildInfo?: any;
}

let config: ConfigType;

export async function getConfig() {
  if (config) return config;

  let env, buildInfo;
  try {
    const response = await fetch("/build.json");
    // fails if env is local, then config assigned in catch block
    const data = await response.json();
    env = data.build.APP_ENV;

    buildInfo = data;
    config = allConfs[env];
  } catch (err) {
    log("Could not load build.json, env is local.");
    env = "local";
    buildInfo = buildInfoLocal;
    config = allConfs.local;
  } finally {
    config.env = env;
    config.buildInfo = buildInfo;
  }
  return config;
}
