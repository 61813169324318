export function jsonSyntaxHighlight(json: any): string {
  if (json === undefined) return "";
  if (typeof json != "string") {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match: any) {
      var cls = "json-number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "json-key";
        } else {
          cls = "json-string";
        }
      } else if (/true|false/.test(match)) {
        cls = "json-boolean";
      } else if (/null/.test(match)) {
        cls = "json-null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}

export function capitalize(string: string) {
  const firstLetter = string.substring(0, 1);
  const rest = string.substring(1);
  const capitalized = firstLetter.toUpperCase() + rest;
  return capitalized;
}

export function convertNameToFileParticle(name: string): string {
  return name.toLowerCase().split(" ").join("_");
}

export function convertBooleanToString(val: boolean): string {
  if (typeof val !== "boolean") {
    return val;
  }
  return val ? "true" : "false";
}
