import Keycloak from "keycloak-js";
import { log } from "../util/logger";
import { ConfigType } from "../config/config";

// Assuming the shape of your config object. Adjust it according to your actual config structure.
interface Config {
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
  };
  url: {
    keycloak: string;
  };
}

interface KeycloakResult {
  keycloak: Keycloak | null;
  isAdmin: boolean;
}

interface UserInfo {
  isSuperUser?: boolean;
  // ... include other properties you expect from loadUserInfo
}

export const getKeycloak = async (
  config: ConfigType
): Promise<KeycloakResult> => {
  const keycloak: Keycloak = new Keycloak({
    url: config.keycloak.url,
    realm: config.keycloak.realm,
    clientId: config.keycloak.clientId,
  });

  keycloak.onTokenExpired = async () => {
    try {
      await keycloak.updateToken(60 * 30);
      log("Token has been updated.");
    } catch (err) {
      log("Error while trying to refresh token: ", err);
    }
  };

  let isAdmin: boolean = false;
  try {
    const authenticated: boolean = await keycloak.init({
      onLoad: "check-sso",
      checkLoginIframe: false,
    });
    log(`User is ${authenticated ? "authenticated" : "not authenticated"}`);
    const userInfo: UserInfo = await keycloak.loadUserInfo();
    isAdmin = userInfo.isSuperUser ?? false;
  } catch (error) {
    console.error("Failed to initialize adapter:", error);
  }

  return { keycloak, isAdmin };
};
