import { convertNameToFileParticle } from "./text";

export function downloadTextFile(data: any, fileIdent?: string) {
  if (!data?.pm?.baseData) return;

  const element = document.createElement("a");
  const dataString = JSON.stringify(data);

  if (!fileIdent) {
    const { firstName, lastName } = data.pm.baseData;
    if (firstName || lastName) {
      fileIdent = `${convertNameToFileParticle(
        firstName
      )}-${convertNameToFileParticle(lastName)}`;
    } else {
      fileIdent = data.pm.baseData.id;
    }
  }

  const fileName = `user-data-${fileIdent}.json`;
  const file = new Blob([dataString], { type: "application/json" });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
