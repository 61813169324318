import Sider from "antd/es/layout/Sider";
import { Header } from "antd/es/layout/layout";
import { Button, Divider, Layout, Space, Image, Tag, Row, Col, Flex } from "antd";
import { ReloadOutlined } from '@ant-design/icons';
import Start from "./pages/Start";
import { cleanUrl } from "./util/cleanUrl";
import "./styles/App.css";
import "./styles/form-styles.css";
import "./styles/Output.css";
import { useContext, useEffect, useState } from "react";
import { Context } from "./context/context";

interface AppProps {
  unauthorized?: boolean;
}

function App({ unauthorized }: AppProps) {
  const { keycloak, isAdmin, api } = useContext(Context);
  const [online, setOnline] = useState(false);
  const authenticated = keycloak.authenticated;

  useEffect(()=>{
    if (!api?.checkBackendOnline) return;

    api.checkBackendOnline().then(state=>setOnline(state));
  }, [api])

  const logout = () => {
    const url = cleanUrl();
    keycloak.logout({ redirectUri: url });
  };

  if (authenticated && !isAdmin && !unauthorized) {
    const urlUnauthorized = window.location.href + "/unauthorized";
    keycloak.logout({ redirectUri: urlUnauthorized });
  }

  const recheck = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    api?.checkBackendOnline().then(state=>setOnline(state))
  };

  return (
    <Space className="App" direction="vertical" size={[0, 48]}>
      <Layout>
        <Sider className="Sider" theme="light" width={180}>
          <Image
            className="logo"
            src="../Teamedio-logo.png"
            alt="Logo of Teamedio"
          />
          <Flex vertical gap="large" align="center" style={{marginTop: 20}}>
          {authenticated && !unauthorized && (
              <Button danger onClick={logout}>
                Logout
              </Button>
            )}
            {online ? (
                <Tag color="lime" >Online</Tag>
              ) : (
                <Tag color="red" closeIcon={<ReloadOutlined/>} onClose={recheck}>Offline</Tag>
              )}
          </Flex>
        </Sider>
        <Layout>
          <Header className="Header">
            <h1>Teamedio Dashboard</h1>
          </Header>
          <Start unauthorized={unauthorized} />
        </Layout>
      </Layout>
    </Space>
  );
}

export default App;
