import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "antd";
import { Context } from "../context/context";
import { checkServices } from "../requests/requests";
import { jsonSyntaxHighlight } from "../util/text";

type StatusTableType = {
  title: string;
  dataIndex: string | string[];
  align?: "left" | "right" | "center";
  render?: (n: any, o?: any) => JSX.Element | string;
};

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false,
};



function toRepoUrl(gitRepo: string) {
  return gitRepo
    ?.replace(":", "/")
    .replace("git@", "https://")
    .replace(/\.git$/, "");
}

export default function Services() {
  const { keycloak, config } = React.useContext(Context);
  const [services, setServices] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<any>();
  const [modalMessage, setModalMessage] = useState<string | undefined>();

  useEffect(() => {
    checkServices(keycloak, config).then((srvc) => {
      const servicesList = Object.keys(srvc)
        .sort()
        .map((name) => ({
          name,
          ...srvc[name],
        }));
      setServices(servicesList);
    });
  }, [config, keycloak]);

  const statusTableColumns: StatusTableType[] = [
    {
      title: "Service",
      dataIndex: "name",
      render: (name, obj) => {
        return (
          <a
            title={obj.description || "no info"}
            href={obj.url}
            target="_blank"
            rel="noreferrer"
          >
            {name}
          </a>
        );
      },
    },
    { title: "Type", dataIndex: "location", align: "center" },
    {
      title: "Status",
      align: "center",
      dataIndex: ["check", "statusOk"],
      render: (ok, item) => {
        if (ok) return "✅";
        if (ok !== false) return "🤔";
        if (!item?.check?.message) return "❌";


        return <Button danger onClick={() => {
          setSelectedService(item);
          setModalMessage(`Error: ${item.check.message}`)
        }}>❌</Button>;
      },
    },
    { title: "Image Tag", dataIndex: ["buildInfo", "branch"] },
    {
      title: "Commit",
      dataIndex: ["buildInfo", "commit"],
      render: (commit, obj) =>
        obj.git ? <a href={toRepoUrl(obj.git)}>{commit}</a> : <>{commit}</>,
    },
    {
      title: "Build Date",
      dataIndex: ["buildInfo", "build", "timestamp"],
      render: (ts, item) => (ts ? <a onClick={() => setSelectedService(item)}>{new Date(ts * 1000).toLocaleDateString('de-DE', DATE_FORMAT)}</a> : ""),
    },
  ];

  return (
    <>
      <Modal open={modalMessage || selectedService?.buildInfo}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        title={selectedService?.name}
        width={800}
        onCancel={() => { setSelectedService(undefined); setModalMessage(undefined) }}>
        <h3>{modalMessage}</h3>
        <div style={{ whiteSpace: "pre", overflow: "scroll" }} dangerouslySetInnerHTML={{ __html: jsonSyntaxHighlight(selectedService?.buildInfo) }}>
        </div>
      </Modal>
      <h2>Services</h2>
      {services && (
        <div style={{ textAlign: "center" }}>
          <h4>Status</h4>
          <Table
            columns={statusTableColumns}
            dataSource={services}
            rowKey="name"
            expandable={{ fixed: "left" }}
            pagination={false}
          />
        </div>
      )}
    </>
  );
}
