import { Table } from "antd";
import { printUserData } from "../util/printUserData";
import { convertNameToFileParticle } from "../util/text";
import { getUniqueKey } from "../util/getUniqueKey";
import { useContext } from "react";
import { Context } from "../context/context";

type ObjectOrArray =
  | {
      [key: string]: any;
    }
  | any[];

// Define the shape of your props
interface ResponseDataProps {
  type: string;
  showUserData: boolean;
  responseData: ResponseDataType;
}

// Define the shape of your state and any complex types used in props
interface ResponseDataType {
  data: ObjectOrArray;
  searchTerm: string;
  searchType: string;
}

export default function ResponseData({
  type,
  showUserData,
  responseData,
}: ResponseDataProps) {
  const { config } = useContext(Context);

  if (!responseData) return null;

  function sentryLink(id: string) {
    return `https://sentry.comjoo.com/organizations/comjoo/issues/?project=3&query=user%3A%22id%3A${id}%22&statsPeriod=14d`;
  }

  function keycloakLink(id: string) {
    return `${config.keycloak.url}/admin/master/console/#/realms/${config.keycloak.realm}/users/${id}`;
  }

  const getEmailsAndIdsOutput = () => {
    if (!Array.isArray(responseData.data) && responseData.data?.hasError) {
      return (
        <p className="error-message">
          Could not find patient with {responseData.searchType}{" "}
          {responseData.searchTerm}.
        </p>
      );
    }
    const columns = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (email: string) => {
          if (responseData.searchType !== "email") return email;
          const searchTerm = responseData.searchTerm;
          const firstMatchingIndex = email.search(searchTerm);
          const printedWord = [];
          for (let i = 0; i < email.length; i++) {
            if (
              i >= firstMatchingIndex &&
              i < firstMatchingIndex + searchTerm.length
            ) {
              printedWord.push(
                <span
                  style={{ backgroundColor: "yellow" }}
                  key={getUniqueKey()}
                >
                  {email[i]}
                </span>
              );
            } else {
              printedWord.push(<span key={getUniqueKey()}>{email[i]}</span>);
            }
          }
          return printedWord;
        },
      },
      {
        title: "PM Id",
        dataIndex: "pmId",
        key: "pmId",
      },
      {
        title: "Fhir Id",
        dataIndex: "fhirId",
        key: "fhirId",
      },
      {
        title: "Links",
        key: "Links",
        render: (e: any) => (
          <>
            <a href={sentryLink(e.pmId)}>
              <img
                src="/logo_sentry.png"
                className="logo_img"
                alt="sentry link"
              />
            </a>
            {e.kcId && (
              <a style={{ marginLeft: 10 }} href={keycloakLink(e.kcId)}>
                <img
                  src="/logo_keycloak.png"
                  className="logo_img"
                  alt="keycloak link"
                />
              </a>
            )}
          </>
        ),
      },
    ];
    const values = responseData.data.map((user: any) => {
      return { ...user, key: getUniqueKey() };
    });
    const paginationConfig = values.length > 10 ? { pageSize: 10 } : false;
    return (
      <Table
        columns={columns}
        dataSource={values}
        pagination={paginationConfig}
      />
    );
  };

  const getUserDataOutput = () => {
    if (!showUserData) return;
    if (
      !Array.isArray(responseData.data) &&
      (responseData.data.hasError || !responseData.data.pm?.baseData)
    ) {
      return (
        <p className="error-message">
          No patient with this {responseData.searchType} has been found.
        </p>
      );
    } else {
      if (!Array.isArray(responseData.data)) {
        const { firstName, lastName } = responseData.data.pm?.baseData;
        const fileName = `user-data-${convertNameToFileParticle(
          firstName
        )}-${convertNameToFileParticle(lastName)}.txt`;
        return (
          <>
            <div className="success">
              <p>
                A file with the data of {firstName} {lastName} has been
                downloaded as {fileName}.
              </p>
            </div>
            <div className="User-Data-div">
              <h4>
                Data of {firstName} {lastName}:
              </h4>
              <h5>Data from PM:</h5>
              <div>{printUserData(responseData.data.pm)}</div>
              <h5>Data from IM:</h5>
              <div>{printUserData(responseData.data.im)}</div>
            </div>
          </>
        );
      }
    }
  };

  const getConsentsOfPatientsOutput = () => {
    if (!Array.isArray(responseData.data) && responseData.data.hasError) {
      return (
        <p className="error-message">
          No consents of "{responseData.searchTerm}" have been found.
        </p>
      );
    } else {
      const columns = [
        {
          title: "Organization",
          dataIndex: "org",
          key: "org",
          render: (text:string, item:any) => <>({item.fhirOrgId}) {text}</>,
        },
        {
          title: "Consent status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Consent Id",
          dataIndex: "consentId",
          key: "consentId",
        },
      ];
      const values = responseData.data.map((consent: any) => {
        return {
          consentId: consent.consentId,
          org: consent.orgName
            ? consent.orgName
            : "-",
          status: consent.status || "active",
          fhirOrgId: consent.fhirOrgId,
          key: getUniqueKey(),
        };
      });
      return (
        <div>
          <p>Consents of "{responseData.searchTerm}":</p>
          <Table columns={columns} dataSource={values} pagination={false} />
        </div>
      );
    }
  };

  const getResetPasswordOutput = () => {
    if (!Array.isArray(responseData.data) && responseData.data.hasError) {
      return <p className="error-message">An unknown error has occurred.</p>;
    } else {
      if (!Array.isArray(responseData.data)) {
        return (
          <div className="success">
            <p>
              The password of the user with the {responseData.searchType} "
              {responseData.searchTerm}" has successfully been reset.
            </p>
            <p>
              The new temporary password is:{" "}
              <span className="output-value">{responseData.data.password}</span>
            </p>
          </div>
        );
      }
    }
  };

  const getChangeEmailOutput = () => {
    if (!Array.isArray(responseData.data) && responseData.data.hasError) {
      return (
        <p className="error-message">Could not change email of patient.</p>
      );
    } else {
      return (
        <p className="success">
          You have successfully changed the email of the patient.
        </p>
      );
    }
  };

  const getConsentsByOrgOutput = () => {
    if (!Array.isArray(responseData.data) && responseData.data.hasError) {
      return (
        <p className="error-message">
          Could not find consents for organization with Fhir org id{" "}
          {responseData.searchTerm}.
        </p>
      );
    } else {
      const columns = [
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "PM Id",
          dataIndex: "pmId",
          key: "pmId",
        },
        {
          title: "Fhir Id",
          dataIndex: "fhirId",
          key: "fhirId",
        },
      ];
      const values = responseData.data.map((user: any) => {
        return {
          ...user,
          key: getUniqueKey(),
        };
      });
      const paginationConfig = values.length > 10 ? { pageSize: 10 } : false;
      return (
        <>
          <p>The organization has {values.length} active consents.</p>
          <Table
            columns={columns}
            dataSource={values}
            pagination={paginationConfig}
          />
        </>
      );
    }
  };

  const getOutputType = () => {
    if (type === "get-id") {
      return getEmailsAndIdsOutput();
    } else if (type === "get-user-data") {
      return getUserDataOutput();
    } else if (type === "reset-password") {
      return getResetPasswordOutput();
    } else if (type === "change-email") {
      return getChangeEmailOutput();
    } else if (type === "consents-patient") {
      return getConsentsOfPatientsOutput();
    } else if (type === "consents-org") {
      return getConsentsByOrgOutput();
    }

    // Default return if none of the above conditions are met
    return null;
  };

  return <div>{getOutputType()}</div>;
}
