import { changeEmail, resetPassword } from "../requests/requests";
import Request from "../components/Request";

export default function Credentials() {
    return (<>
        <h2>Credentials</h2>
        <Request
            type='reset-password'
            title='Reset Password'
            requestFunction={resetPassword}
            showModal
        />
        <Request
            type='change-email'
            title='Change Email'
            requestFunction={changeEmail}
            displayNewEmailInput
            showModal
        />
    </>)
}