import React from "react";
import {
  getOrgsFromPatientConsents,
  getPatientsFromOrgConsents,
} from "../requests/requests";
import Request from "../components/Request";
import TotalConsents from "../components/TotalConsents";
import UpdateConsents from "../components/UpdateConsents";

export default function ConsentCounts() {
  return (
    <>
      <h2>Consents</h2>
      <TotalConsents />
      <Request
        title="Get Consents of Patient"
        type="consents-patient"
        requestFunction={getOrgsFromPatientConsents}
        displayPicker
      />
      <Request
        title="Get Consents of Organization"
        type="consents-org"
        requestFunction={getPatientsFromOrgConsents}
        displayPicker
      />
      <UpdateConsents />
    </>
  );
}
