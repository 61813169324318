import { Modal } from "antd";
import { getDataTypeDisplay } from "../util/getDataTypeDisplay";

interface CustomModalProps {
  type: string;
  searchType: string;
  searchTerm: string | null;
  newEmail: string;
  displayModal: boolean;
  handleOkModal: (...a: any) => any;
  handleCancelModal: (...a: any) => any;
}

const CustomModal: React.FC<CustomModalProps> = ({
  type,
  searchType,
  searchTerm,
  newEmail,
  displayModal,
  handleOkModal,
  handleCancelModal,
}) => {
  const getModalContent = () => {
    if (type === "reset-password") {
      return (
        <>
          <p>
            Do you really want to reset the password of the user with the{" "}
            {searchType} "{searchTerm}"?
          </p>
        </>
      );
    } else if (type === "change-email") {
      const getByDataText =
        searchType === "email"
          ? "Old email address"
          : getDataTypeDisplay(searchType);
      return (
        <>
          <p>
            Do you really want to change the email address of the user with the{" "}
            {searchType} "{searchTerm}"?
          </p>
          <p>
            {getByDataText}: <span className="output-data">{searchTerm}</span>
          </p>
          <p>
            New email address: <span className="output-data">{newEmail}</span>
          </p>
        </>
      );
    }
  };

  const getOkText = () => {
    if (type === "reset-password") {
      return "Reset Password";
    } else if (type === "change-email") {
      return "Change Email";
    }
  };

  return (
    <Modal
      open={displayModal}
      onOk={handleOkModal}
      onCancel={handleCancelModal}
      okType="danger"
      okText={getOkText()}
    >
      {getModalContent()}
    </Modal>
  );
};

export default CustomModal;
