import { Button, Flex, Typography } from 'antd'
import { DownloadOutlined } from '@ant-design/icons';
import { useState } from 'react'
import UserFinder from './UserFinder';
import { User } from '../requests/requests';
import { downloadTextFile } from "../util/downloadTextFile";
import { jsonSyntaxHighlight } from '../util/text';

function UserData({showUser}:{showUser?: User}) {
  const [user, setUser] = useState<User|undefined>(showUser);

  function displayUser(user: User) {
    return (
      <div style={{whiteSpace: "pre"}} dangerouslySetInnerHTML={{__html:jsonSyntaxHighlight(user)}}>
      </div>
    )
  }
  return (
    <div>
      {user ? <>
        <Flex justify="space-between">
          <h4>{user.descriptor()}</h4>
          <Button icon={<DownloadOutlined />} onClick={() => downloadTextFile(user)}>Download</Button>
        </Flex>
        <div>
          {displayUser(user)}
        </div>
      </>
        :
        <UserFinder onUserFound={(foundUser: any) => setUser(foundUser)} />
      }
    
    </div>
  )
}

export default UserData