import { Form, Select } from "antd";
import { getDataTypeDisplay } from "../util/getDataTypeDisplay";

const dataTypeOptions = [
  {
    label: getDataTypeDisplay("email"),
    value: "email",
  },
  {
    label: getDataTypeDisplay("pmId"),
    value: "pmId",
  },
  {
    label: getDataTypeDisplay("fhirId"),
    value: "fhirId",
  },
];

interface SearchTypeProps {
  defaultSearchType: string;
  handleSearchTypeChange: (...a: any) => any;
  required?: boolean;
}

export default function SearchType({
  defaultSearchType,
  handleSearchTypeChange,
  required = true,
}: SearchTypeProps) {
  return (
    <Form.Item
      label="Get by"
      className="select-data-type"
      required={required}
      colon
    >
      <Select
        options={dataTypeOptions}
        defaultValue={defaultSearchType}
        style={{ width: "fit-content" }}
        dropdownStyle={{ minWidth: 85 }}
        onChange={handleSearchTypeChange}
      />
    </Form.Item>
  );
}
